import React from "react"
import Header from "../components/Header"
import Content from "../components/Content"
import { Container, SectionHeading } from "../components/Sections"
import Image from "../components/Image"

function TestimonialsPageTemplate() {
  return (
    <>
      {/* <Header heading={heading} subheading={subheading} />
      <Content html={html} /> */}
      <Container>
        <div className="max-w-3xl mx-auto">hello</div>
      </Container>
    </>
  )
}

export default TestimonialsPageTemplate
