import React from "react"
import { graphql } from "gatsby"
import MyHelmet from "../components/MyHelmet"
import TestimonialsPageTemplate from "./TestimonialsPageTemplate"

const TestimonialsPage = ({ data }) => {
  // const { markdownRemark: post } = data
  // const { frontmatter: fm } = post

  return (
    <>
      {/* <MyHelmet title={fm.title} description={fm.subheading} /> */}
      <TestimonialsPageTemplate></TestimonialsPageTemplate>
    </>
  )
}

export default TestimonialsPage

export const testimonialsPageQuery = graphql`
  query TestimonialsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        subheading
        team {
          name
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 640
                placeholder: BLURRED
                aspectRatio: 1.5
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`
